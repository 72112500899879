<template>
  <div>
    <!-- banner -->
    <div style="padding-top:60px">
      <el-carousel height="720px">
        <el-carousel-item v-for="item in bannerList"
                          :key="item.id">
          <img class="banner-img"
               :src="item.imgUrl"
               @click="LinkTo(item.id)"
               alt="">
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="banner-btn"
           @click="LiJiTiyan"></div> -->
    </div>
    <!-- content -->
    <div class="ls-box">
      <div class="center-box">
        <div class="content-title">
          <div>海豚管家使用流程</div>
          <div>多平台，不限量，一店一IP</div>
        </div>
        <div class="lc-list">
          <div class="lc-step-box">
            <div :class="['lc-step',i == LcActive?'step-bg':'']"
                 @mouseover="LcMouseover(i)"
                 v-for="(step,i) in lcList[LcActive].step"
                 :key="i">
              <div>{{step.title}}</div>
              <div>{{step.desc}}</div>
            </div>
          </div>
          <div class="lc-img">
            <img :src="lcList[LcActive].imgUrl"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="hx-box">
      <div class="center-box">
        <div class="content-title">
          <div>核心优势</div>
          <div>海豚管家完美解决店群店铺关联处罚问题</div>
        </div>
        <div class="hx-list-box">
          <div class="hx-list"
               v-for="(item,index) in hxList"
               :key="index">
            <div>
              <img :src="item.imgUrl">
              {{item.title}}
            </div>
            <div class="hx-desc">
              <div>· {{item.desc1}}</div>
              <div>· {{item.desc2}}</div>
              <div>· {{item.desc3}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sm-box">
      <div class="center-box">
        <div class="sm-content">
          <div class="sm-tips">
            <div>立即扫码添加客服，领取您的专属海豚管家</div>
            <div>海豚管家-店群商家都在用的云服务器软件</div>
            <div @click="LiJiTiyan">立即下载</div>
          </div>
          <div>
            <img src="../assets/images/pc/wxcode.jpg"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="yz-box">
      <div class="center-box">
        <div class="content-title">
          <div>优质服务 为您保驾护航</div>
          <div>手把手教学，一对一服务</div>
        </div>
        <div class="yz-list-box">
          <div class="yz-list"
               v-for="(item,index) in yzList"
               :key="index">
            <div><img :src="item.imgUrl"
                   alt=""></div>
            <div>{{item.name}}</div>
            <div>{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      bannerList: [
        { imgUrl: require('../assets/images/pc/banner.png'), id: 1 },
        { imgUrl: require('../assets/images/pc/banner2.png'), id: 2 },
      ],
      lcList: [
        {
          imgUrl: require('../assets/images/pc/lc1.png'),
          step: [
            { title: '第一步：注册账号，获取邀请码', desc: '联系客服获取' },
            { title: '第二步：新增云服务', desc: '生成云服务，授权店铺' },
            { title: '第三步：聊天台', desc: '多店铺聚合聊天，人工+机器人智能回复' },
          ],
        },
        {
          imgUrl: require('../assets/images/pc/lc2.png'),
          step: [
            { title: '第一步：注册账号，获取邀请码', desc: '联系客服获取' },
            { title: '第二步：新增云服务', desc: '生成云服务，授权店铺' },
            { title: '第三步：聊天台', desc: '多店铺聚合聊天，人工+机器人智能回复' },
          ],
        },
        {
          imgUrl: require('../assets/images/pc/lc3.png'),
          step: [
            { title: '第一步：注册账号，获取邀请码', desc: '联系客服获取' },
            { title: '第二步：新增云服务', desc: '生成云服务，授权店铺' },
            { title: '第三步：聊天台', desc: '多店铺聚合聊天，人工+机器人智能回复' },
          ],
        },
      ],
      hxList: [
        { imgUrl: require('../assets/images/md/youshi1.png'), title: '专属登录环境', desc1: '一账号一专属登录环境', desc2: '干净稳定', desc3: '关联性弱' },
        { imgUrl: require('../assets/images/md/youshi2.png'), title: '无需远程', desc1: '地域随机 (首次获取地域随机)', desc2: '不限流量', desc3: '无需远程' },
        { imgUrl: require('../assets/images/md/youshi3.png'), title: '账号安全管理', desc1: '同时管理多个平台店铺', desc2: '一台设备轻松解决电商运营难题', desc3: '随时随地安全登录账号' },
        { imgUrl: require('../assets/images/md/youshi4.png'), title: '聚合聊天台', desc1: '支持多店铺聚合聊天', desc2: '人工+机器人智能回复', desc3: '有效提升客服响应率' },
      ],
      yzList: [
        { imgUrl: require('../assets/images/md/footer1.png'), name: '7*14小时', desc: '在线客服解答' },
        { imgUrl: require('../assets/images/md/footer2.png'), name: '一对多', desc: 'VIP售后群服务' },
        { imgUrl: require('../assets/images/md/footer3.png'), name: '定期培训', desc: '专业团队上门指导' },
      ],

      LcActive: 0,

    }
  },
  created () {
    // console.log(window.location.href)
  },
  methods: {
    LcMouseover (index) {
      // console.log(index)
      this.LcActive = index
    },
    LiJiTiyan () {
      this.$router.push('/download')
      this.setSessionItem('routerName', '/download')
    },
    toJinTun () {
      window.location.href = window.PLATFROM_CONFIG.JTWebUrl
    },
    LinkTo (id) {
      if (id == '1') {
        this.LiJiTiyan()
      }
      if (id == '2') {
        this.toJinTun()
      }
    },
  }
}
</script>

<style lang="less" scoped>
.banner-img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
